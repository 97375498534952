export interface Email {
    id: number;
    sent: Date;
    emails: string[];
    files: any[];
    subject: string;
    description: string;
}
export interface Notification {
    id: number;
    idAuthor: number;
    idUser: number;
    idWorkorder: number;
    titleEs: string;
    titleEn: string;
    messageEs: string;
    messageEn: string;
    isRead: boolean;
    isDeleted: boolean;
    emailSent: boolean;
    pushSent: boolean;
    date: Date;
    dateRead: Date;
    dateDeleted: Date;
    type: number;
    app: string;
    extra: string;
}
export interface Parameter {
    id?: number;
    value: string;
}
export interface ShittyParameter {
    idDato?: number;
    nombre: string;
}

export interface User {
    id: number;
    internalCode: number;
    name: string;
    email: string;
    logo: string;
    roles: Role[];
    dealers?: Dealer[];
    isDetailer: boolean;
    token: string;
    provider: any;
    isChecked?: boolean;
    ownerLogo: string;
    permissions?: number[];
}

export interface ShittyUser {
    id: number;
    codigoInterno: number;
    nombre: string;
    email: string;
    password: string;
    celular: string;
    rol: any[];
    roles: ShittyRole[];
    rolesRel: any[];
    contratistas: ShittyDealer[];
    empresa: any;
    fechaCreacion: string;
    fechaUpdate: string;
    logoImg: string;
    estado: number;
    isDetailer: boolean;
    token: string;
    contratistaOwner: { logoImg: string };
}

export interface ShittyRole {
    id: number;
    nombre: string;
    dealer: ShittyDealer;
}

export interface Role {
    id: number;
    name: string;
    dealer: Dealer;
}

export interface ShittyDealer {
    id: number;
    logoImg: string;
    razonSocial: string;
    lat: string;
    lng: string;
    radio: string;
    permisos: any[];
    isImgRequired?: string;
    hasStockRequired?: string;
    hasPoRequired?: string;
    hasRoRequired?: string;
    hasTagRequired?: string;
    hasSalesRequired?: string;
    hasKTAAddEvents?: number;
    hasKTAChangeStatus?: number;
    ktaActions?: { actionName: string; id: string }[];
}

export interface ShittyDealerAsigned {
    id: number;
    logoImg: string;
    razonSocial: string;
}

export interface Dealer {
    id: number;
    logo: string;
    name: string;
    active?: boolean;
    woImageEnabled?: boolean;
    stockNumberRequired?: boolean;
    poRequired?: boolean;
    roRequired?: boolean;
    tagRequired?: boolean;
    salesRequired: boolean;
    permissions: number[];
    ktaActions: { [id: string]: { label: string } };
    hasKTAAddEvents: boolean;
    hasKTAChangeStatus: boolean;
}
export interface ResponseCar {
    vin: string;
    year: string;
    make: ShittyParameter;
    model: ShittyParameter;
    dealer: Dealer;
    color: string;
    tipo: string;
    stockNumber: string;
    miles: number;
    gasoline: string;
    isLoaned: boolean;
    loanActive: boolean;
    damages: any[];
    id: number;
    estado: number;
    loanWorkflow: number;
    customer: any;
    isTouch: boolean;
    lat: number;
    lng: number;
    lastLocationDate: string;
    authorLastLocation: ShittyUser;
    statusKey: string;
    qrService?: any;
    jsonHeldBy?: User;
    cantEvents: number;
}

export interface RequestCar {
    customer: any;
    dealer: Dealer;
    make: ShittyParameter;
    model: ShittyParameter;
    tipo: string;
    vin: string;
    year: string;
    color: string;
    stockNumber: string;
    miles: number;
    gasoline: string;
    id: number;
    estado: number;
}

export interface Car {
    id: number;
    vinNumber?: string;
    shortVin?: string;
    stockNumber?: string;
    year: string;
    maker: Parameter;
    model: Parameter;
    color: string;
    responseCar?: ResponseCar;
    type: string;
    isTouch: boolean;
    lat: number;
    lng: number;
    lastLocationDate: Date;
    authorLastLocation: User;
    qrService?: QRCodeCarService;
    hasKey?: boolean;
    heldBy: string;
    eventsTotal: number;
    miles: number;
    selected?: boolean;
}

export interface Invoice {
    id: number;
    ro: any;
    po: any;
    tag: any;
    miles: string;
    entryDate: Date;
    promisedDate: Date;
    observation: string;
    priority: number;
    status: number;
    workOrder: number;
    fullWo: string;
    workflow: Workflow;
    department: Department;
    dealerProvider: Dealer;
    invoiceServices: Service[];
    services: string;
    servicesString: string;
    salesman: string;
    createdBy: string;
    approvedBy: string;
    damage: any;
    provider: any;
    employees: any[];
    employeeIds: number[];
    accessEdit: boolean;
    isApproved: null | '0' | '1';
    allowApprove: boolean;
    car?: Car;
    stockNumber?: string;
    images?: number;
    selected?: boolean;
    woImage?: string;
    isBilled: boolean;
    pauseNote: string;
    totalCost: number;
}

export interface Workflow {
    id: number;
    value: string;
}

export interface Department {
    id: number;
    name: string;
    dealer?: Dealer;
}

export interface Service {
    id: number;
    name: string;
    price: number;
    piecework: number;
    isChecked?: boolean;
    employeesPiecework: any;
    pos?: number;
    qty?: number;
    hasQty?: boolean;
    stockNumberRequired?: boolean;
    poRequired?: boolean;
    roRequired?: boolean;
    tagRequired?: boolean;
    salesRequired?: boolean;
}

export interface ResponseQRCode {
    id: number;
    qrCode: string;
    dateCreated: string;
    author?: ShittyUser;
    dealer: ShittyDealer;
    detailer: ShittyDealer;
    qrCodeCarRel?: {
        id: string;
        car?: ResponseCar;
        author?: ShittyUser;
    };
}

export interface QRCode {
    id: number;
    code: string;
    createdAt: Date;
    dealer: Dealer;
    detailer: Dealer;
    car?: Car;
    carCodeId?: number;
    author: User;
}
export interface QRCodeCarServiceEvent {
    id: number;
    action: {
        id: number;
        name: string;
        screenName: string;
        type: string;
        pos: number;
    };
    author: User;
    note: string;
    userNote: string;
    date: Date;
    type: string;
    lat: number;
    lng: number;
    photoName: string;
    hasKey: boolean;
}
export interface QRCodeCarService {
    id: number;
    events: QRCodeCarServiceEvent[];
    locations: QRCodeCarServiceEvent[];
    hasKey: boolean;
    heldBy?: {
        name: string;
        date: Date;
    };
}

export const getQRCodeFromResponse = (r: ResponseQRCode): QRCode => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        code: r.qrCode,
        createdAt: r.dateCreated ? toDateFromString(r.dateCreated) : null,
        dealer: convertShittyDealer(r.dealer),
        detailer: convertShittyDealer(r.detailer),
        car: r.qrCodeCarRel ? getCarFromResponse(r.qrCodeCarRel.car) : null,
        carCodeId: +r.qrCodeCarRel?.id,
        author: r.qrCodeCarRel ? convertShittyUser(r.qrCodeCarRel.author) : null,
    };
};

export const getServiceFromResponse = (r: any): Service => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre,
        price: r.price,
        piecework: r.piecework,
        employeesPiecework: r.employeesPiecework,
        pos: r.pos ? +r.pos : Infinity,
        qty: r.qty ? +r.qty : 0,
        hasQty: r.hasQty === '1' ? true : false,
        stockNumberRequired: r.hasStockRequired === '1' ? true : false,
        poRequired: r.hasPoRequired === '1' ? true : false,
        roRequired: r.hasRoRequired === '1' ? true : false,
        tagRequired: r.hasTagRequired === '1' ? true : false,
        salesRequired: r.hasSalesRequired === '1' ? true : false,
    };
};

export const getDepartmentFromResponse = (r: any): Department => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.detail.nombre,
    };
};

export const convertShittyWorkflow = (r: any): Workflow => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        value: r.descripcion,
    };
};

export const pad = (n: number) => {
    if (n < 10) {
        return '0' + n;
    }
    return n;
};

export const toStringDate = (date: Date, withoutTime?: boolean): string => {
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        (!withoutTime ? ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) : '')
    );
};

export const toDateFromString = (date: string): Date => {
    const parts = date.split(' ');
    return new Date(`${parts[0]}T${parts[1]}Z`);
};

export const invoiceToRequest = (i: Invoice, userId: number, carId: number, dealerId: number) => {
    return {
        id: i.id,
        author: {
            id: userId,
        },
        car: {
            id: carId,
        },
        dealerProvider: {
            id: i.provider.id,
        },
        ro: i.ro,
        po: i.po,
        tag: i.tag,
        miles: i.miles,
        fechaAlta: toStringDate(i.entryDate),
        promiseDatetime: i.promisedDate ? toStringDate(i.promisedDate) : null,
        observation: i.observation,
        priority: i.priority,
        status: i.status,
        wo: i.workOrder,
        workflow: i.workflow,
        department: i.department,
        salesman: i.salesman,
        invoiceServices: i.invoiceServices.map((ii) => {
            return {
                invoiceService: { id: ii.id },
                price: ii.price,
                piecework: ii.piecework,
                employeesPiecework: ii.employeesPiecework,
                pos: ii.pos,
                qty: ii.qty,
                hasQty: ii.hasQty,
            };
        }),
        prioridad: 1,
        damage: JSON.stringify(i.damage),
        employees: i.employees,
        stockNumber: i.stockNumber,
    };
};

export const convertFromShittyParameter = (r: ShittyParameter): Parameter => {
    if (!r) {
        return { value: '' };
    }
    return {
        id: r.idDato,
        value: r.nombre,
    };
};

export const convertToShittyParameter = (r: Parameter): ShittyParameter => {
    if (!r) {
        return null;
    }
    return {
        idDato: r.id,
        nombre: r.value,
    };
};

export const carToRequest = (car: Car): RequestCar => {
    if (!car) {
        return null;
    }
    return {
        ...car.responseCar,
        tipo: car.type,
        year: car.year,
        color: car.color,
        make: convertToShittyParameter(car.maker),
        model: convertToShittyParameter(car.model),
        stockNumber: car.stockNumber,
        vin: car.vinNumber,
        miles: car.miles ? car.miles : null,
    };
};

export const convertShittyDealer = (r: ShittyDealer, permisos?: { id: string }[]): Dealer => {
    if (!r) {
        return null;
    }
    permisos = permisos ? permisos : r.permisos;
    let ktaActions;
    if (r.ktaActions) {
        ktaActions = {};
        r.ktaActions.forEach((a) => {
            ktaActions[+a.id] = { label: a.actionName };
        });
    }
    return {
        id: r.id,
        name: r.razonSocial,
        logo: checkLogo(r.logoImg),
        permissions: permisos && permisos.map((p) => +p.id),
        woImageEnabled: r.isImgRequired === '1' ? true : false,
        stockNumberRequired: r.hasStockRequired === '1' ? true : false,
        poRequired: r.hasPoRequired === '1' ? true : false,
        roRequired: r.hasRoRequired === '1' ? true : false,
        tagRequired: r.hasTagRequired === '1' ? true : false,
        salesRequired: r.hasSalesRequired === '1' ? true : false,
        ktaActions,
        hasKTAAddEvents: r.hasKTAAddEvents ? true : false,
        hasKTAChangeStatus: r.hasKTAChangeStatus ? true : false,
    };
};

export const getInvoiceFromResponse = (r: any): Invoice => {
    if (!r) {
        return null;
    }
    let hasAddedService = false;
    let promisedDate = r.uPromiseDatetime && new Date(Number(r.uPromiseDatetime) * 1000);
    let promisedDateColor;
    if (promisedDate) {
        const promisedTime = promisedDate.getTime();
        const currentTime = Date.now();
        const hours = (currentTime - promisedTime) / 1000 / 60 / 60;
        promisedDateColor = hours > 24 ? 'black' : hours >= 12 ? 'orange' : 'red';
    }
    return {
        id: r.id,
        ro: r.ro,
        po: r.po,
        tag: r.tag,
        miles: r.miles,
        entryDate: r.dateTimestamp ? new Date(r.dateTimestamp * 1000) : toDateFromString(r.fechaAlta),
        promisedDate: r.promiseDatetime && toDateFromString(r.promiseDatetime),
        observation: r.observation,
        priority: r.priority,
        status: r.status,
        workOrder: r.fullNro,
        fullWo: r.fullNro,
        workflow: convertShittyWorkflow(r.workflow),
        dealerProvider: convertShittyDealer(r.dealerProvider),
        department: {
            id: r.department.id,
            name: r.department.nombre,
            dealer: convertShittyDealer(r.department.dealer),
        },
        services: r.invoiceServicesNames
            ? r.invoiceServicesNames.split(',').map((s) => {
                  const parts = s.split('|');
                  if (parts.length > 1) {
                      return {
                          id: +parts[2],
                          name: parts[0],
                          note: parts[1],
                          user: parts[3],
                          date: parts[4],
                      };
                  } else {
                      return {
                          name: s,
                      };
                  }
              })
            : [],
        servicesString: r.invoiceServicesNames
            ? r.invoiceServicesNames
                  .split(',')
                  .map((s) => {
                      const parts = s.split('|');
                      if (parts.length > 1) {
                          if (parts.length > 4) {
                              if (parts[5] && parts[5] !== '1') {
                                  hasAddedService = true;
                                  return `<span style="color: var(--ion-color-primary)">${parts[0]} ${
                                      parts[1] ? '(' + parts[1] + ')' : ''
                                  }</span>`;
                              } else {
                                  return `<span>${parts[0]} ${parts[1] ? '(' + parts[1] + ')' : ''}</span>`;
                              }
                          } else {
                              return `<span>${parts[0]} ${parts[1] ? '(' + parts[1] + ')' : ''}</span>`;
                          }
                      } else {
                          return s;
                      }
                  })
                  .join(',')
            : '',
        salesman: r.salesman,
        createdBy: r.author && r.author.nombre,
        approvedBy: r.approver && r.approver.nombre,
        provider: { id: r.dealerProvider.id },
        employeeIds: r.employeesIds ? r.employeesIds.split(',').map((v) => Number(v)) : [],
        invoiceServices:
            r.invoiceServices &&
            r.invoiceServices.map((iv) => ({
                id: iv.invoiceService.id,
                name: iv.invoiceService.nombre,
                invoicePrice: iv.invoiceService.price,
                price: iv.price,
                piecework: iv.piecework,
                employeesPiecework:
                    iv.employeesPiecework &&
                    iv.employeesPiecework.map((ep) => ({
                        idService: iv.invoiceService.id,
                        idEmployee: ep.idEmployee,
                        piecework: ep.piecework,
                        id: ep.id,
                        estado: ep.estado,
                    })),
                pos: iv.pos && +iv.pos,
                qty: iv.qty !== '0' ? +iv.qty : 0,
                hasQty: iv.hasQty === '1' ? true : false,
            })),
        damage: r.damage ? JSON.parse(r.damage) : null,
        employees: r.employees ? r.employees.map((e) => ({ id: e.id })) : null,
        accessEdit: r.accessEdit,
        isApproved: r.isApproved,
        allowApprove: r.allowApprove === '1' ? true : false,
        car: r.car ? getCarFromResponse(r.car) : null,
        stockNumber: r.stockNumber,
        images: Number(r.haveImage),
        selected: false,
        woImage: r.haveWoImage,
        isBilled: r.isBilled === '1' ? true : false,
        pauseNote: r.pauseNote,
        totalCost: r.total ? +r.total : 0,
    };
};

export const checkLogo = (logo: string) => {
    if (!logo || logo.includes('emptylogo')) {
        return null;
    } else {
        return logo;
    }
};

export const convertShittyRole = (r: ShittyRole): Role => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre,
        dealer: convertShittyDealer(r.dealer),
    };
};

export const convertShittyUser = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    let roles;
    let dealers;
    let provider;
    if (r.roles) {
        roles = r.roles.map((rl) => convertShittyRole(rl));
        if (r.isDetailer) {
            dealers = r.rolesRel.map((rl) => convertShittyDealer(rl.dealerAsigned));
            provider = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.companiaOrigen, rl.rol.permisos));
        } else {
            dealers = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.dealer)).slice(0, 1);
            provider = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.companiaOrigen, rl.rol.permisos)).slice(0, 1);
        }
    }
    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles,
        logo: r.logoImg,
        dealers,
        isDetailer: r.isDetailer,
        token: r.token,
        provider,
        ownerLogo: r.contratistaOwner?.logoImg,
    };
};

export const getEmployeeFromRequest = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles: null,
        logo: null,
        dealers: null,
        isDetailer: null,
        token: null,
        provider: null,
        isChecked: false,
        ownerLogo: r.contratistaOwner?.logoImg,
    };
};

export const getEmailFromResponse = (r: any): Email => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        description: r.descripcion,
        emails: r.emails.map((e) => e.email),
        sent: new Date(r.fecha),
        files: r.files.map((f) => ({
            name: f.nameFile,
            queue: f.emailQueue,
            description: f.descripcion,
            extension: f.extension,
            id: f.id,
        })),
        subject: r.asunto,
    };
};

export const getCarFromResponse = (r: ResponseCar): Car => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        vinNumber: r.vin && r.vin.toUpperCase(),
        shortVin: r.vin && r.vin.slice(8, 17),
        year: r.year,
        maker: convertFromShittyParameter(r.make),
        model: convertFromShittyParameter(r.model),
        color: r.color,
        responseCar: r,
        stockNumber: r.stockNumber ? r.stockNumber.toUpperCase() : null,
        type: r.tipo,
        isTouch: !!+r.isTouch,
        lat: +r.lat,
        lng: +r.lng,
        lastLocationDate: r.lastLocationDate ? toDateFromString(r.lastLocationDate) : null,
        authorLastLocation: convertShittyUser(r.authorLastLocation),
        qrService: r.qrService
            ? {
                  id: +r.qrService.id,
                  events: r.qrService.events ? r.qrService.events.map((s) => getQREventFromResponse(s)) : [],
                  locations: r.qrService.locations ? r.qrService.locations.map((s) => getQREventFromResponse(s)) : [],
                  hasKey: r.qrService ? r.qrService.haveKey : false,
                  heldBy:
                      r.qrService && r.qrService.heldBy
                          ? {
                                name: r.qrService.heldBy.nombre,
                                date: toDateFromString(r.qrService.heldBy.fecha),
                            }
                          : null,
              }
            : null,
        hasKey: r.statusKey && r.statusKey === '1' ? true : false,
        heldBy: r.jsonHeldBy ? r.jsonHeldBy.name : null,
        eventsTotal: r.cantEvents,
        miles: r.miles ? +r.miles : null,
    };
};

export const getQREventFromResponse = (s): QRCodeCarServiceEvent => {
    if (!s) {
        return null;
    }
    return {
        action: {
            id: +s.action.id,
            name: s.action.actionName,
            screenName: s.action.actionScreenName,
            pos: +s.action.pos,
            type: s.action.typeEvent,
        },
        author: convertShittyUser(s.author),
        date: s.fechaISO ? new Date(s.fechaISO) : null,
        hasKey: s.hasKey && s.hasKey === '1',
        photoName: s.photoName,
        id: +s.id,
        lat: +s.lat,
        lng: +s.lng,
        note: s.note,
        type: s.type,
        userNote: s.userNote,
    };
};
