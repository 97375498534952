import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from '../../app.config';
import { User, convertShittyUser, Dealer, convertShittyDealer } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { BaseService } from '../BaseService';
import { GlobalEvents } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';
import * as memoizee from 'memoizee';
export interface UserSignUpRequest extends User {
    tipo: 'create_mobile';
    id_usuario?: number;
}

export interface UserSignInRequest {
    codigoInterno?: string;
    user?: string;
    password?: string;
}

@Injectable()
export class AuthService extends BaseService {
    public static token: string = null;
    public static storage: StorageService;
    public static deviceModel: string;
    public static pushId: string;
    error: string;
    user: User = null;
    client: any = null;
    activeDealer: Dealer;
    activeProvider: any;
    public userEndpoint = `${this.config.apiEndpoint}modulos/mobile/json.usuario.php`;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        private translate: TranslateService,
        alertController: AlertController,
        globalEvents: GlobalEvents
    ) {
        super(http, globalEvents, alertController);
        AuthService.storage = this.storage;
        this.initService();
    }

    async initService() {
        AuthService.token = await this.storage.get('token');
        const u = await this.storage.get('profile');
        this.user = u;
        if (u) {
            const pushId = await this.storage.get('pushId');
            if (pushId) {
                AuthService.pushId = pushId;
                this.updatePush(pushId);
            }
        }
    }

    public static async getToken() {
        return await AuthService.storage.get('token');
    }
    public async updatePush(pushId: string) {
        if (this.user && this.user.id) {
            await this.apiPost(`${this.config.nodeEndpoint}/users/${this.user.id}/update_push?hide_loading`, {
                pushId,
                appName: this.config.appName,
            });
        }
        return;
    }

    public login(credentials: UserSignInRequest): Promise<User> {
        const uploadsFolder = `${this.config.apiEndpoint}/uploads/`;
        return new Promise((resolve, reject) => {
            this.http.post(`${this.config.apiEndpoint}/php/api/login.php`, credentials).subscribe(
                async (resp: any) => {
                    this.storage.get('pushId').then((pushId) => {
                        this.updatePush(pushId);
                    });
                    let isAdmin = false;
                    resp.data.roles.forEach((r) => {
                        if (Number(r.id) === 2) {
                            isAdmin = true;
                        }
                    });
                    if (!isAdmin) {
                        this.user = convertShittyUser(resp.data);
                        this.user.ownerLogo = !this.user.ownerLogo.includes('http')
                            ? `${uploadsFolder}${this.user.ownerLogo}`
                            : this.user.ownerLogo;
                        AuthService.token = this.user.token;
                        this.storage.set('profile', this.user);
                        this.storage.set('token', this.user.token);
                        resolve(this.user);
                    } else {
                        reject({ msg: 'INVALID_USER_ROLE' });
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    public async logout() {
        await this.updatePush(null);
        await this.emptyStorage();
        this.activeDealer = this.activeProvider = this.user = this.client = null;
    }

    public async getUser() {
        if (!this.user) {
            this.user = await this.storage.get('profile');
        }
        return this.user;
    }

    public getProfileId() {
        return this.user.id;
    }

    public authenticated() {
        return this.storage.get('profile').then((t) => {
            if (t && t.id) {
                return true;
            } else {
                return false;
            }
        });
    }

    async getActiveDealer() {
        if (!this.activeDealer) {
            this.activeDealer = await this.storage.get('activeDealer');
        }
        return this.activeDealer;
    }

    async getActiveProvider() {
        if (!this.activeProvider) {
            this.activeProvider = await this.storage.get('activeProvider');
        }
        return this.activeProvider;
    }

    setActiveProvider(provider: Dealer) {
        const uploadsFolder = `${this.config.apiEndpoint}/uploads/`;
        this.activeProvider = provider;
        this.activeProvider.logo =
            this.activeProvider.logo && !this.activeProvider.logo.includes('http')
                ? `${uploadsFolder}${this.activeProvider.logo}`
                : this.activeProvider.logo;
        this.storage.set('activeProvider', this.activeProvider);
        return this.activeProvider;
    }

    async emptyStorage() {
        await this.storage.clear();
        await this.storage.set('pushId', AuthService.pushId);
    }

    async getActiveIds() {
        const user = await this.getUser();
        const provider = await this.getActiveProvider();
        const dealer = await this.getActiveDealer();
        return {
            idUsuario: user && user.id,
            idDealer: dealer && dealer.id,
            idDealerProvider: provider && provider.id,
        };
    }

    setActiveDealer(dealer: Dealer, pos?: number) {
        const uploadsFolder = `${this.config.apiEndpoint}/uploads/`;
        dealer.logo = dealer.logo && !dealer.logo.includes('http') ? `${uploadsFolder}${dealer.logo}` : dealer.logo;
        this.activeDealer = dealer;
        this.storage.set('activeDealer', dealer);
        if (pos !== undefined) {
            this.setActiveProvider(this.user.provider[pos]);
        }
        return dealer;
    }

    async dealerSelect(dealers: Dealer[], resolve: any) {
        this.translate.get(['SELECT_DEALER', 'ACCEPT', 'CANCEL']).subscribe(async (res) => {
            const inputs = [];
            for (let i = 0; i < dealers.length; i++) {
                const element = dealers[i];
                inputs.push({
                    name: `car${i}`,
                    type: 'radio',
                    label: `${element.name}`,
                    value: i,
                    checked: dealers[i].active,
                });
            }
            const alert = await this.alertCtrl.create({
                header: res.SELECT_DEALER,
                inputs,
                buttons: [
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                    {
                        text: res.ACCEPT,
                        handler: (i: number) => {
                            const dealer = this.user.dealers[i];
                            this.setActiveDealer(dealer, i);
                            resolve(dealer);
                        },
                    },
                ],
            });
            await alert.present();
        });
    }

    getRemoteDealer = memoizee(this.remoteDealer, { maxAge: 7000, promise: true });

    async remoteDealer(id: number) {
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/api.dealers.php?id_dealer=${id}&id_author=${this.user.id}&hide_loading`
        );
        return convertShittyDealer(data);
    }
}
