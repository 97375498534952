import { NgModule } from '@angular/core';
import { PhonePipe } from './phone.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';

@NgModule({
    declarations: [PhonePipe, SafeHtmlPipe],
    imports: [],
    exports: [PhonePipe, SafeHtmlPipe],
})
export class PipesModule {}
