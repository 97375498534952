import { Injectable } from '@angular/core';
import { ModalController, IonRouterOutlet } from '@ionic/angular';
import { Car, Dealer, Parameter, QRCode, User } from '../models';
import { StorageService } from '../services/storage.service';
import { BuscarVehiculoComponent } from './buscar-vehiculo/buscar-vehiculo.modal';
import { CarListComponent } from './car-list/car-list.component';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';
import { QrDetailComponent } from './qr-detail/qr-detail.component';

@Injectable({
    providedIn: 'root',
})
export class ModalHelper {
    constructor(private modalController: ModalController, private storage: StorageService) {}

    async viewQrDetail(routerOutlet: IonRouterOutlet, componentProps: { qrData: QRCode }) {
        const modal = await this.modalController.create({
            component: QrDetailComponent,
            componentProps,
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
    }

    async scanner(routerOutlet: IonRouterOutlet): Promise<string | Car> {
        const modal = await this.modalController.create({
            component: BuscarVehiculoComponent,
            componentProps: {
                qrEnabled: true,
                hideKeyboardEnabled: true,
                type: 'qr',
                title: 'QR-BARCODE',
            },
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data && data.qr) {
            return data.qr;
        }
        if (data && data.cars && data.cars.length === 1) {
            return data.cars[0];
        } else if (data && data.cars && data.cars.length) {
            const car = await this.selectCar(routerOutlet, {
                sourceList: data.cars,
            });
            return car;
        } else {
            return null;
        }
    }

    async searchVehicle(
        routerOutlet: IonRouterOutlet,
        componentProps?: {
            vinEnabled?: boolean;
            tagEnabled?: boolean;
            stockEnabled?: boolean;
            phoneEnabled?: boolean;
            showSegment?: boolean;
            searchWithCellphone?: boolean;
            type: 'vin' | 'tag' | 'phone' | 'stock' | 'qr';
            findOrCreate?: boolean;
            hideKeyboardEnabled?: boolean;
            qrId?: number;
        }
    ): Promise<Car> {
        const scannerEnabled = await this.storage.get('scannerEnabled', false);
        if (scannerEnabled) {
            componentProps.hideKeyboardEnabled = true;
        }
        const modal = await this.modalController.create({
            component: BuscarVehiculoComponent,
            componentProps,
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data && data.cars && data.cars.length === 1) {
            return data.cars[0];
        } else if (data && data.cars && data.cars.length) {
            const car = await this.selectCar(routerOutlet, {
                sourceList: data.cars,
            });
            return car;
        } else {
            return null;
        }
    }

    async searchEmployee(
        routerOutlet: IonRouterOutlet,
        componentProps?: {
            sourceList: User[];
            initSelected?: number;
            multiple?: boolean;
            multipleSelected?: Partial<User>[];
        }
    ): Promise<User | User[]> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'name' },
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        return data?.found;
    }

    async selectDealer(
        routerOutlet: IonRouterOutlet,
        componentProps?: {
            sourceList: Dealer[];
            initSelected: number;
            title: string;
        }
    ): Promise<{ dealer: Dealer; pos: number }> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'name' },
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data) {
            return { dealer: data.found, pos: data.index };
        } else {
            return null;
        }
    }
    async selectCar(routerOutlet: IonRouterOutlet, componentProps?: { sourceList: Car[] }): Promise<Car> {
        const modal = await this.modalController.create({
            component: CarListComponent,
            componentProps,
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data && data.car) {
            return data.car;
        } else {
            return null;
        }
    }

    async selectParameter(
        routerOutlet: IonRouterOutlet,
        componentProps?: {
            sourceList: Parameter[];
            initSelected?: number;
            multiple?: boolean;
            multipleSelected?: Partial<Parameter>[];
        }
    ): Promise<Parameter> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'value' },
            swipeToClose: false,
            presentingElement: routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        return data && data.found;
    }
}
