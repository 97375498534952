import { Component } from '@angular/core';
import { AlertController, ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { VehicleService } from '../../providers/vehicle-service/vehicle.service';
import { Car } from 'src/app/models';
import { HttpService } from 'src/app/interceptors/http.service';
import { debounce } from 'src/app/debounce';
import { BarcodeService } from 'src/app/services/barcode.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Keyboard } from '@capacitor/keyboard';
import { CarUtils } from 'src/app/car.utils';

@Component({
    selector: 'app-buscar-vehiculo',
    templateUrl: './buscar-vehiculo.modal.html',
    styleUrls: ['./buscar-vehiculo.modal.scss'],
})
export class BuscarVehiculoComponent {
    type: 'vin' | 'tag' | 'phone' | 'stock' | 'qr';
    vinNumber: string;
    stockNumber: string;
    qrNumber: string;
    image: any;
    fileinput: any;
    focused = false;
    stockEnabled: boolean;
    vinEnabled: boolean;
    phoneEnabled: boolean;
    tagEnabled: boolean;
    qrEnabled: boolean;
    showSegment: boolean;
    searchWithCellphone: boolean;
    searchCellphone: string;
    tagNumber: number;
    phoneNumber: number;
    ocrWarning = false;
    findOrCreate: boolean;
    hideKeyboardEnabled: boolean;
    keyboardHideTypes = ['vin', 'stock'];
    qrId?: number;
    title = 'SEARCH_TITLE';
    carUtils: CarUtils;

    constructor(
        private alertController: AlertController,
        private barcodeService: BarcodeService,
        private translate: TranslateService,
        private actionSheetCtrl: ActionSheetController,
        private vehicleService: VehicleService,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private platform: Platform
    ) {
        this.carUtils = new CarUtils(translate, alertController, vehicleService);
    }

    change = debounce((e) => {
        this.checkVin(e.detail.value);
    }, 150);

    checkVin(vinToCheck: string) {
        let vin = vinToCheck.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\\|,.<>\/? ñÑ´]/g, '').toUpperCase();
        if (vin) {
            if (vin.length === 18) {
                vin = vin.substr(1, 18);
            }
            if (vin.length > 17) {
                vin = vin.substr(0, 17);
            }
        }
        this.vinNumber = vin;
        return vin;
    }

    changeTag(e) {
        if (e.detail.value.length > 6) {
            setTimeout(() => {
                this.tagNumber = e.detail.value.substr(0, 6);
            }, 0);
        }
    }

    changePhone(e) {
        if (e.detail.value.length > 10) {
            setTimeout(() => {
                this.phoneNumber = e.detail.value.substr(0, 10);
            }, 0);
        }
    }

    async scan() {
        this.ocrWarning = false;
        const barcode = await this.barcodeService.scan();
        if (barcode) {
            this.checkVin(barcode);
            this.carUtils.continueVin(this.vinNumber, this.qrId, (car) => {
                this.setCarAndContinue(car);
            });
        }
    }

    continueVin() {
        this.carUtils.continueVin(this.vinNumber, this.qrId, (car) => {
            this.setCarAndContinue(car);
        });
    }

    setCarAndContinue(cars: Car[], service?: any) {
        this.dismiss({ cars, service });
    }

    async ionViewDidEnter() {
        this.focusVin();
    }

    focusVin() {
        setTimeout(() => {
            if (this.vinEnabled && this.type === 'vin' && this.hideKeyboardEnabled) {
                this.setInputEvent('vinInput');
                this.setInputFocus('vinInput');
            }
            if (this.qrEnabled && this.type === 'qr' && this.hideKeyboardEnabled) {
                this.setInputEvent('qrInput');
                this.setInputFocus('qrInput');
            }
        }, 100);
    }

    continueQr() {
        if (this.qrNumber.length === 17) {
            this.vinNumber = this.qrNumber;
            this.carUtils.continueVin(this.vinNumber, this.qrId, this.setCarAndContinue);
        } else {
            this.dismiss({ qr: this.qrNumber });
        }
    }

    setInputEvent(input: string) {
        (document.getElementById(input).children[0] as any).addEventListener('keypress', async (e) => {
            if (e.key === 'Enter') {
                try {
                    if (this.qrEnabled) {
                        this.continueQr();
                    }
                    await Keyboard.hide();
                } catch (error) {}
            }
        });
    }

    setInputFocus(input: string) {
        (document.getElementById(input).children[0] as any).focus();
        setTimeout(async () => {
            try {
                await Keyboard.hide();
            } catch (error) {}
        }, 50);
    }

    ionViewWillEnter() {
        this.vinNumber = '';
        this.stockNumber = '';
        this.qrNumber = '';
        this.tagNumber = null;
        this.phoneNumber = null;
        this.vehicleService.setActiveCar(null);
    }

    segmentChange(event) {
        setTimeout(() => {
            if (this.hideKeyboardEnabled && this.keyboardHideTypes.includes(this.type)) {
                this.setInputFocus(`${this.type}Input`);
                this.setInputEvent(`${this.type}Input`);
            }
        }, 50);
        this.vinNumber = '';
        this.stockNumber = '';
    }

    async continueTag() {
        // if (String(this.tagNumber).length >= 3 && String(this.tagNumber).length <= 6) {
        //     const service = await this.vehicleService.findServiceByFilter({ tag: this.tagNumber });
        //     if (service) {
        //         this.setCarAndContinue(service.car, service);
        //     } else {
        //         this.translate.get(['NO_SERVICE_FOUND', 'ACCEPT']).subscribe(async (res) => {
        //             const alert = await this.alertController.create({
        //                 header: res.NO_SERVICE_FOUND,
        //                 buttons: [
        //                     {
        //                         text: res.ACCEPT,
        //                     },
        //                 ],
        //             });
        //             await alert.present();
        //         });
        //     }
        // } else {
        //     this.translate.get(['ENTER_VALID_TAG', 'ACCEPT']).subscribe(async (res) => {
        //         const alert = await this.alertController.create({
        //             header: res.ENTER_VALID_TAG,
        //             buttons: [
        //                 {
        //                     text: res.ACCEPT,
        //                 },
        //             ],
        //         });
        //         await alert.present();
        //     });
        // }
    }

    async continuePhone() {
        // if (String(this.phoneNumber).length > 5) {
        //     const service = await this.vehicleService.findServiceByFilter({ cellphoneLike: this.phoneNumber });
        //     if (service) {
        //         this.setCarAndContinue(service.car, service);
        //     } else {
        //         this.translate.get(['NO_SERVICE_FOUND', 'ACCEPT']).subscribe(async (res) => {
        //             const alert = await this.alertController.create({
        //                 header: res.NO_SERVICE_FOUND,
        //                 buttons: [
        //                     {
        //                         text: res.ACCEPT,
        //                     },
        //                 ],
        //             });
        //             await alert.present();
        //         });
        //     }
        // } else {
        //     this.translate.get(['ENTER_VALID_PHONE', 'ACCEPT']).subscribe(async (res) => {
        //         const alert = await this.alertController.create({
        //             header: res.ENTER_VALID_PHONE,
        //             buttons: [
        //                 {
        //                     text: res.ACCEPT,
        //                 },
        //             ],
        //         });
        //         await alert.present();
        //     });
        // }
    }

    continueStock() {
        if (this.stockNumber) {
            this.vehicleService.findCarByStock(this.stockNumber).then((car) => {
                this.setCarAndContinue(car);
            });
        }
    }

    async takeAndroidPicture() {
        const photo = await Camera.getPhoto({
            quality: 60,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl,
        });
        this.image = photo.dataUrl;
        await this.clearPicturesFolder();
        this.doOCR();
    }

    async clearPicturesFolder() {
        if (this.platform.is('android')) {
            const picturesFolder = await Filesystem.getUri({ directory: Directory.External, path: 'Pictures' });
            Filesystem.readdir({ path: picturesFolder.uri }).then((dir) => {
                dir.files.forEach((file) => {
                    Filesystem.deleteFile({ path: picturesFolder.uri + '/' + file });
                });
            });
        }
    }

    handleFileSelect(evt) {
        const files = evt.target.files;
        const file = files[0];
        this.image = null;
        if (files && file) {
            const reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        const binaryString = readerEvt.target.result;
        this.image = 'data:image/jpeg;base64,' + btoa(binaryString);
        this.doOCR();
    }

    async doOCR() {
        this.fileinput = null;
        if (this.image) {
            HttpService.turnOnModal();
            const resp = await this.vehicleService.getVinOCR(this.image);
            if (resp.length === 0) {
                HttpService.turnOffModal();
                this.noOCRResponseAlert();
            } else {
                HttpService.turnOffModal();
                if (resp.length > 1) {
                    const inputs = [];
                    for (let i = 0; i < resp.length; i++) {
                        const element = resp[i];
                        inputs.push({
                            name: `vin${i}`,
                            type: 'radio',
                            label: element,
                            value: element,
                        });
                    }
                    this.translate.get(['SELECT_VIN', 'ACCEPT', 'CANCEL']).subscribe(async (res) => {
                        const alert = await this.alertController.create({
                            header: res.SELECT_VIN,
                            inputs,
                            id: 'vinSelector',
                            buttons: [
                                {
                                    text: res.CANCEL,
                                    role: 'cancel',
                                },
                                {
                                    text: res.ACCEPT,
                                    handler: (value) => {
                                        this.vinNumber = value;
                                        this.ocrWarning = true;
                                    },
                                },
                            ],
                        });
                        await alert.present();
                        // make vin show whole in android
                        document
                            .querySelector('#vinSelector')
                            .querySelectorAll('div.alert-radio-label.sc-ion-alert-md')
                            .forEach((e) => {
                                e.setAttribute('style', 'padding-inline-start:40px;font-size:14px;');
                            });
                    });
                } else {
                    this.vinNumber = resp[0];
                    this.ocrWarning = true;
                }
            }
        }
    }

    presentActionSheet() {
        this.translate.get(['TAKE_PHOTO', 'SELECT_PHOTO', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                buttons: [
                    {
                        text: res.TAKE_PHOTO,
                        handler: () => {
                            this.takeAndroidPicture();
                        },
                    },
                    {
                        text: res.SELECT_PHOTO,
                        handler: () => {
                            document.getElementById('file-button').click();
                        },
                    },
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
        });
    }

    async noOCRResponseAlert() {
        const t = await this.translate.get(['OCR_404', 'ACCEPT']).toPromise();
        const alert = await this.alertController.create({
            message: t.OCR_404,
            buttons: [
                {
                    text: t.ACCEPT,
                },
            ],
        });
        await alert.present();
    }

    async setCellphone() {
        const text = await this.translate.get(['OK', 'CANCEL', 'EDIT_CELLPHONE', 'PHONE_NMBR']).toPromise();
        const approveAlert = await this.alertCtrl.create({
            header: text.EDIT_CELLPHONE,
            inputs: [
                {
                    name: 'cellphone',
                    type: 'number',
                    placeholder: text.PHONE_NMBR,
                    value: this.searchCellphone,
                    attributes: {
                        maxlength: 10,
                    },
                },
            ],
            buttons: [
                {
                    text: text.CANCEL,
                    role: 'cancel',
                },
                {
                    text: text.OK,
                    handler: async (value) => {
                        if (value.cellphone && value.cellphone.length >= 10) {
                            this.searchCellphone = value.cellphone.substring(0, 10);
                        } else {
                        }
                    },
                },
            ],
        });
        await approveAlert.present();
        await approveAlert.onDidDismiss();
        this.focusVin();
    }

    removeCellphone() {
        this.searchCellphone = null;
        this.focusVin();
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
