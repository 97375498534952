import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export const showAlert = async (message: string, translate: TranslateService, alertCtrl: AlertController) => {
    const r = await translate.get(['OK', message]).toPromise();
    const alert = await alertCtrl.create({
        message: r[message],
        buttons: [
            {
                text: r.OK,
                role: 'ok',
            },
        ],
    });
    await alert.present();
};

export const confirmAction = async (
    params: {
        action?: string;
        message?: string;
        yesMode?: boolean;
        callback;
        cancelCallback?;
        yesText?: string;
        noText?: string;
        cssClass?: string;
    },
    translate: TranslateService,
    alertCtrl: AlertController
) => {
    const { action, callback, cancelCallback, yesMode, message, yesText, noText, cssClass } = params;
    const textsToSearch = ['OK', 'CANCEL', 'YES', 'NO', action];
    if (yesText) {
        textsToSearch.push(yesText);
    }
    if (noText) {
        textsToSearch.push(noText);
    }
    const text = await translate.get(textsToSearch).toPromise();
    const confirmAlert = await alertCtrl.create({
        header: action ? text[action] : null,
        message,
        cssClass,
        buttons: [
            {
                text: noText ? text[noText] : yesMode ? text.NO : text.CANCEL,
                role: 'cancel',
                handler: async () => {
                    if (cancelCallback) {
                        await cancelCallback();
                    }
                },
            },
            {
                text: yesText ? text[yesText] : yesMode ? text.YES : text.OK,
                handler: async () => {
                    await callback();
                },
            },
        ],
    });
    await confirmAlert.present();
};
