import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from 'src/app/providers/invoice-service/invoice.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  recentEmails: string[] = [];
  selectedEmails: string[] = [];
  emailInput = '';
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  editMode = false;
  constructor(
    private invoiceService: InvoiceService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private alertController: AlertController,
  ) { }

  async ngOnInit() {
    this.recentEmails = await this.invoiceService.getRecentEmails();
  }

  editRecent(){
    this.editMode = !this.editMode;
  }

  remove(email: string) {
    if (email) {
      this.selectedEmails = this.selectedEmails.filter( e => e !== email);
      this.recentEmails.push(email);
    }
  }

  removeRecent(email: string) {
    if (email) {
      this.recentEmails = this.recentEmails.filter( e => e !== email);
      this.invoiceService.deleteRecentEmail(email);
      if(!this.recentEmails.length){
        this.editMode = false;
      }
    }
  }

  addRecent(email: string){
    if (email) {
      this.recentEmails = this.recentEmails.filter( e => e !== email);
      this.selectedEmails.push(email);
    }
  }

  async add(){
    const {
      ERROR, CANCEL,
      ACCEPT, EMPTY_MAIL_ERROR,
      INVALID_MAIL_ERROR,
      MAIL_SEND_ERROR, MAIL_SEND_SUCCESS
    } = await this.translate.get([
      'ERROR', 'CANCEL', 'ACCEPT',
      'EMPTY_MAIL_ERROR', 'INVALID_MAIL_ERROR',
      'MAIL_SEND_ERROR', 'SUCCESS', 'MAIL_SEND_SUCCESS'
    ]).toPromise();
    if(this.emailInput){
      if (!this.re.test(this.emailInput.toLowerCase())) {
        this.presentAlert({title: ERROR, message: INVALID_MAIL_ERROR});
      } else {
        if(!this.selectedEmails.includes(this.emailInput.toLowerCase())){
          this.selectedEmails.push(this.emailInput.toLowerCase());
          if(this.recentEmails.includes(this.emailInput.toLowerCase())){
            this.removeRecent(this.emailInput.toLowerCase());
          }
          this.emailInput = '';
        }
      }
    }
  }

  accept(){
    this.dismiss(this.selectedEmails.join(','));
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  async presentAlert(dataAlert: any, callback?: any) {
    const { OK } = await this.translate.get(['OK']).toPromise();
    const alert = await this.alertController.create({
      header: dataAlert.title,
      message: dataAlert.message,
      buttons: [{
        text: OK,
        handler: (values) => {
          if (callback) {
            callback();
          }
        }
      }]
    });
    await alert.present();
  }

}
