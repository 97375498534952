import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ActionSheetController,
    PopoverController,
    AlertController,
    NavController,
    ModalController,
    IonRouterOutlet,
} from '@ionic/angular';
import { InformationService } from '../../providers/information-service/information.service';
import { AuthService } from '../../providers/auth-service/auth.service';
import { User } from '../../models';
import { GlobalEvents } from '../../services/events.service';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    user: User;
    appVersion: string;
    scannerEnabled: boolean;
    constructor(
        @Inject(APP_CONFIG) config: IAppConfig,
        private translate: TranslateService,
        public actionSheetCtrl: ActionSheetController,
        private popoverController: PopoverController,
        private authService: AuthService,
        private navCtrl: NavController,
        public alertController: AlertController,
        private informationService: InformationService,
        private events: GlobalEvents,
        private storage: StorageService
    ) {
        this.appVersion = config.appVersion;
    }

    async ngOnInit() {
        this.user = await this.authService.getUser();
        this.storage.get('scannerEnabled', false).then((value) => {
            this.scannerEnabled = value;
        });
    }

    dismissPopover() {
        this.popoverController.dismiss();
    }

    presentActionSheet() {
        this.dismissPopover();
        this.translate.get(['LANGUAGE', 'ENGLISH', 'SPANISH', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                header: res.LANGUAGE,
                buttons: [
                    {
                        text: res.ENGLISH,
                        handler: () => {
                            this.changeLang('en');
                        },
                    },
                    {
                        text: res.SPANISH,
                        handler: () => {
                            this.changeLang('es');
                        },
                    },
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
        });
    }

    async logout() {
        const t = await this.translate.get(['LOGOUT', 'CANCEL', 'ACCEPT']).toPromise();
        const alert = await this.alertController.create({
            header: t.LOGOUT,
            buttons: [
                {
                    text: t.CANCEL,
                    role: 'cancel',
                },
                {
                    text: t.ACCEPT,
                    handler: async () => {
                        await this.authService.logout();
                        this.navCtrl.navigateRoot('login');
                    },
                },
            ],
        });
        this.dismissPopover();
        await alert.present();
    }

    async changeDealer() {
        this.dismissPopover();
        this.events.publish({ event: 'change_dealer_modal', data: null });
    }

    viewQRDetail() {
        this.dismissPopover();
        this.events.publish({ event: 'qr_detail', data: null });
    }

    scannerEnabledChange() {
        this.storage.set('scannerEnabled', this.scannerEnabled);
    }

    changeLang(lang) {
        this.informationService.changeLang(lang);
    }
}
