import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig, APP_CONFIG } from '../../app.config';
import { AuthService } from '../auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {
    Parameter,
    convertFromShittyParameter,
    Department,
    Dealer,
    getDepartmentFromResponse,
    Service,
    getServiceFromResponse,
    Workflow,
    convertShittyWorkflow,
    User,
    getEmployeeFromRequest,
} from '../../models';
import { StorageService } from 'src/app/services/storage.service';
@Injectable({
    providedIn: 'root',
})
export class InformationService {
    public lang: string;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private http: HttpClient,
        private storage: StorageService,
        private authService: AuthService,
        private translate: TranslateService
    ) {}

    getInvoiceWorkflows() {
        return new Promise<Workflow[]>(async (resolve, reject) => {
            this.http
                .post(`${this.config.apiEndpoint}/php/api/api.workflow.php`, {
                    tipo: 2,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => convertShittyWorkflow(i));
                        resolve(invoices);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getProvidersByUser() {
        return new Promise<Dealer[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http.post(`${this.config.apiEndpoint}/php/api/api.dealers.php`, activeIds).subscribe(
                (resp: any) => {
                    const invoices = (resp.data as any[]).map(
                        (i) =>
                            ({
                                id: i.id,
                                name: i.razonSocial,
                                logo: i.logoImg,
                            } as Dealer)
                    );
                    resolve(invoices);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getDepartments() {
        return new Promise<Department[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http.post(`${this.config.apiEndpoint}/php/api/department.php`, activeIds).subscribe(
                (resp: any) => {
                    const invoices = (resp.data as any[]).map((i) => getDepartmentFromResponse(i));
                    resolve(invoices);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getServices(id: number, idInvoice?: any) {
        return new Promise<Service[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http
                .post(`${this.config.apiEndpoint}/php/api/invoiceService.php`, {
                    ...activeIds,
                    idDepartment: id,
                    showScanner: 1,
                    idInvoice,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => getServiceFromResponse(i));
                        resolve(invoices);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getEmployees() {
        return new Promise<User[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http
                .post(`${this.config.apiEndpoint}/php/api/api.employees.php?hide_loading`, {
                    ...activeIds,
                    filterEmployee: 1,
                    showScanner: 1,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => getEmployeeFromRequest(i));
                        resolve(invoices);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async getColors() {
        const activeIds = await this.authService.getActiveIds();
        const lastColorFetchId: number = await this.storage.get('lastColorFetchId');
        const lastColors = await this.storage.get('colors');
        return new Promise<Parameter[]>(async (resolve, reject) => {
            if (lastColorFetchId !== activeIds.idDealer || !lastColors) {
                this.http
                    .post(`${this.config.apiEndpoint}/php/api/colors.php`, {
                        ...activeIds,
                    })
                    .subscribe(
                        async (resp: any) => {
                            const colors = (resp.data as any[]).map((i) => convertFromShittyParameter(i));
                            await this.storage.set('lastColorFetchId', activeIds.idDealer);
                            await this.storage.set('colors', colors);
                            resolve(colors);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            } else {
                resolve(lastColors);
            }
        });
    }

    async setColors() {
        const colors = await this.getColors();
        await this.storage.set('colors', colors);
    }

    getModel(id: number) {
        return new Promise<Parameter[]>((resolve, reject) => {
            this.http
                .get(`${this.config.apiEndpoint}/php/api/makemodel.php?maker_id=${id}&models=1`)
                .subscribe((resp: any) => {
                    const modelData = resp.data.map((i) => convertFromShittyParameter(i));
                    resolve(modelData);
                });
        });
    }

    getMakers() {
        return new Promise<Parameter[]>((resolve, reject) => {
            this.http.get(`${this.config.apiEndpoint}/php/api/makemodel.php`).subscribe((resp: any) => {
                const makerData = resp.data.map((i) => convertFromShittyParameter(i));
                resolve(makerData);
            });
        });
    }

    async getLang() {
        if (!this.lang) {
            this.lang = await this.storage.get('lang', 'en');
        }
        return this.lang;
    }

    changeLang(lang: string) {
        this.translate.use(lang);
        this.storage.set('lang', lang);
        this.lang = lang;
    }
}
