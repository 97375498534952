import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Car, ResponseCar, getCarFromResponse, carToRequest, getInvoiceFromResponse } from 'src/app/models';
import { AuthService } from '../auth-service/auth.service';
import { BaseService } from '../BaseService';
import { GlobalEvents } from 'src/app/services/events.service';
import { AlertController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { Browser } from '@capacitor/browser';

@Injectable({
    providedIn: 'root',
})
export class VehicleService extends BaseService {
    private activeCar: Car;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        private authService: AuthService,
        globalEvents: GlobalEvents,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
        this.getActiveCar();
    }

    async getActiveCar() {
        if (!this.activeCar) {
            this.activeCar = await this.storage.get('activeCar');
        }
        return this.activeCar;
    }

    async updateCar(car: Car) {
        const activeIds = await this.authService.getActiveIds();
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/car.php`, {
            dealer: { id: activeIds.idDealer },
            idAuthor: activeIds.idUsuario,
            ...carToRequest(car),
        });
        const updatedCar = getCarFromResponse(resp.data);
        this.setActiveCar(updatedCar);
        return updatedCar;
    }

    setActiveCar(car: Car) {
        this.activeCar = car;
        this.storage.set('activeCar', car);
        return car;
    }

    async getVinOCR(image: string): Promise<string[]> {
        const resp = await this.apiPost(`${this.config.nodeEndpoint}/ocr`, { image });
        return resp;
    }

    async updateColor(id: number, color: string) {
        await this.apiPut(`${this.config.nodeEndpoint}/parking/car/${id}/color`, { color });
    }

    async findCarByVin(vinNumber: string, idQr?: number, forceAssociate?: boolean) {
        const ids = await this.authService.getActiveIds();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.searchvinv2.php`, {
            vin: vinNumber,
            tipo: 'find',
            idDealer: ids.idDealer,
            idQr,
            idAuthor: ids.idUsuario,
            lat: this.events.lastCoords.latitude,
            lng: this.events.lastCoords.longitude,
            forceAssociate,
        });
        if (resp.error && resp.error.code === '-11') {
            throw resp;
        }
        const responseCars = (resp.data as ResponseCar[]).map((c) => getCarFromResponse(c));
        return responseCars;
    }

    async createCarByVin(vinNumber: string) {
        const dealer = await this.authService.getActiveDealer();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.searchvinv2.php`, {
            vin: vinNumber,
            tipo: 'createRapidapi17',
            idDealer: dealer.id,
        });
        const responseCars = await this.findCarByVin(vinNumber);
        return responseCars;
    }

    async updateVin8to17(vinNumber: string, idCar: number) {
        const dealer = await this.authService.getActiveDealer();
        const user = await this.authService.getUser();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.searchvinv2.php`, {
            vin17: vinNumber,
            tipo: 'updateVin8to17',
            idDealer: dealer.id,
            idAuthor: user.id,
            idCar,
        });
        return getCarFromResponse(resp.data);
    }

    async createCarByVin2(vinNumber: string) {
        const dealer = await this.authService.getActiveDealer();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.searchvinv2.php`, {
            vin: vinNumber,
            tipo: 'createRapidapi17',
            idDealer: dealer.id,
        });
        return getCarFromResponse(resp.data[0]);
    }

    async getCarYear(vinNumber: string) {
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/vinYear.php?code=${vinNumber}`);
        return resp.data.year;
    }

    async findCarInvoices(params: { id: number; limit?: number; providerId?: string; idWorkflowIn?: string }) {
        const { id, limit, providerId, idWorkflowIn } = params;
        const user = await this.authService.getUser();
        const ids = await this.authService.getActiveIds();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            idDealerProvider: ids.idDealerProvider ? ids.idDealerProvider : null,
            idDealer: ids.idDealer ? ids.idDealer : null,
            filter: 1,
            idCar: id,
            showScanner: 1,
            idUserLogged: !user.isDetailer ? user.id : null,
            limit,
            allowUserApprove: user.id,
            orderBy: 'i.fecha_alta DESC',
            idWorkflowIn,
        });
        const invoices = (resp.data as any[]).map((i) => getInvoiceFromResponse(i));
        return invoices;
    }

    async findCarByStock(stockNumber: string) {
        const resp = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/car.php?stock=${stockNumber}&id_dealer=${this.authService.activeDealer.id}`
        );
        const responseCars = resp.data !== 'null' ? (resp.data as ResponseCar[]).map((c) => getCarFromResponse(c)) : [];
        return responseCars;
    }

    async validateStock(stock: string) {
        const { idDealer } = await this.authService.getActiveIds();
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.car_validate.php`, {
            idDealer,
            idCar: this.activeCar.id,
            idAuthor: 1,
            tipo: 'checkStock',
            stock,
        });
        if (data && data.codigo === 1) {
            return data.vin;
        }
        return null;
    }

    async updateCarFields(car: Car, updateFields: string) {
        const activeIds = await this.authService.getActiveIds();
        return this.apiPut(`${this.config.apiEndpoint}/php/api/car.php`, {
            idAuthor: activeIds.idUsuario,
            ...car,
            updateFields,
        });
    }

    async sendEvent(params: {
        idEvent: number;
        photoName?: string;
        qrCode?: number;
        nombre?: string;
        userNote?: string;
    }) {
        const { idEvent, photoName, qrCode, nombre, userNote } = params;
        if (this.activeCar) {
            const { idUsuario } = await this.authService.getActiveIds();
            const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/kta/api.ktainfo.php`, {
                idEvent,
                idAuthor: idUsuario,
                lat: this.events.lastCoords.latitude,
                lng: this.events.lastCoords.longitude,
                idCar: this.activeCar.id,
                photoName,
                qrCode,
                nombre,
                userNote,
            });
            return getCarFromResponse(resp.data);
        } else {
            return null;
        }
    }

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    submitImage(serviceId: number, image: string) {
        const blob = this.dataURItoBlob(image);
        const fd = new FormData(document.forms[0]);
        fd.append('file', blob);
        return new Promise<string>(async (resolve, reject) => {
            this.http
                .post(`${this.config.nodeEndpoint}/key_tracker/service/img_upload`, fd, {
                    reportProgress: true,
                    observe: 'events',
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve(event.body.fileUrl);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async fetchCars(
        dateEventFrom: string,
        eventUserId: string,
        vinLikeRight: string,
        ktaLastEventIdIn: string,
        withoutEvents: boolean
    ) {
        const { idDealer } = await this.authService.getActiveIds();
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/modulos/cars/`, {
            idDealer,
            dateEventFrom,
            eventUserId,
            vinLikeRight,
            limit: 50,
            ktaLastEventIdIn: ktaLastEventIdIn != '0' ? ktaLastEventIdIn : null,
            withoutEvents: withoutEvents ? 1 : null,
        });
        return data.map((car) => getCarFromResponse(car)) as Car[];
    }

    async printLabels(carIds: string) {
        const url = `${this.config.nodeEndpoint}/key_tracker/qr_codes/generate_car?carIds=${carIds}&format=4x2`;
        await Browser.open({ url });
    }

    async sendLabelsEmail(idsCars: string, emailto: string) {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/sendmail.php`, {
            tipo: 'sendmail-labels',
            idsCars,
            emailto,
            idDealer,
            idAuthor: idUsuario,
        });
    }
}
