import { Component, Inject, ViewChild } from '@angular/core';
import { AlertController, IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from './app.config';
import OneSignal from 'onesignal-cordova-plugin';
import OSNotification from 'onesignal-cordova-plugin/dist/OSNotification';
import { NotificationService } from './providers/notification-service/notification.service';
import { GlobalEvents } from './services/events.service';
import { StatusBar } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { StorageService } from './services/storage.service';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { AuthService } from './providers/auth-service/auth.service';
import { App } from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    isKeyboardActive = false;
    isUpdateAlertOpen = false;
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private platform: Platform,
        private storage: StorageService,
        public translate: TranslateService,
        private navCtrl: NavController,
        private notificationService: NotificationService,
        private events: GlobalEvents,
        private alertController: AlertController
    ) {
        translate.addLangs(['es', 'en']);
        this.events.getObservable().subscribe((e) => this.handleGlobalEvents(e));
        this.initializeApp();
    }

    async initializeApp() {
        await this.storage.init();
        const lang = await this.storage.get('lang', 'en');
        this.translate.setDefaultLang(lang);
        const platform = await this.platform.ready();
        try {
            AuthService.token = await this.storage.get('token');
            const deviceInfo = await Device.getInfo();
            AuthService.deviceModel = `${deviceInfo.manufacturer} - ${deviceInfo.model} - ${deviceInfo.osVersion}`;
            if (platform === 'dom') {
                await this.storePushId((await Device.getId()).uuid);
                window.addEventListener('blur', () => {
                    this.events.activeWindow = false;
                });
                window.addEventListener('focus', () => {
                    this.events.activeWindow = true;
                });
            } else {
                /// ONE SIGNAL SETUP
                OneSignal.setAppId(this.config.pushAppId);
                OneSignal.setNotificationWillShowInForegroundHandler((event) => {
                    const notification = event.getNotification();
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title,
                                body: notification.body,
                                sound: notification.sound,
                                extra: notification,
                                id: Date.now(),
                            },
                        ],
                    });
                    event.complete(null);
                });
                OneSignal.setNotificationOpenedHandler((event) => {
                    if (!event.action.type) {
                        //  this.handleNotifications(event.notification);
                    }
                });
                OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                    console.log('User accepted notifications: ' + accepted);
                });
                (OneSignal.getDeviceState as any)(async (state) => {
                    await this.storePushId(state.userId);
                });
                OneSignal.clearOneSignalNotifications();
                /// ONE SIGNAL SETUP
                StatusBar.setOverlaysWebView({ overlay: false });
                StatusBar.setBackgroundColor({ color: '#87B93A' });

                Keyboard.addListener('keyboardWillShow', () => {
                    this.toggleFooter(true);
                });

                Keyboard.addListener('keyboardWillHide', () => {
                    this.toggleFooter(false);
                });
                this.platform.backButton.subscribeWithPriority(-1, () => {
                    if (!this.routerOutlet.canGoBack()) {
                        App.exitApp();
                    }
                });
            }
        } catch (e) {}
    }

    async storePushId(pushId: string) {
        await this.storage.set('pushId', pushId);
        AuthService.pushId = pushId;
    }

    handleNotificationsReceived(data: OSNotification) {}

    async handleGlobalEvents(e: { event: string; data: any }) {
        if (e.event === 'NEW_UPDATE') {
            if (!this.isUpdateAlertOpen) {
                const t = await this.translate.get(['UPDATE_TITLE', 'UPDATE_TEXT', 'UPDATE_BUTTON']).toPromise();
                const alert = await this.alertController.create({
                    header: t.UPDATE_TITLE,
                    message: t.UPDATE_TEXT,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: t.UPDATE_BUTTON,
                            handler: () => {
                                let storeUrl;
                                if (this.platform.is('android')) {
                                    storeUrl = this.config.playStore;
                                } else if (this.platform.is('ios')) {
                                    storeUrl = this.config.appStore;
                                }
                                if (storeUrl) {
                                    Browser.open({
                                        url: storeUrl,
                                    });
                                    // this.browser.create(storeUrl);
                                    this.isUpdateAlertOpen = false;
                                }
                            },
                        },
                    ],
                });
                await alert.present();
                this.isUpdateAlertOpen = true;
            }
        }
    }

    toggleFooter(state: boolean) {
        const footers = document.querySelectorAll('ion-footer');
        if (footers) {
            footers.forEach((f) => {
                f.classList.toggle('hide', state);
            });
        }
    }
}
