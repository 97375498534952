import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { confirmAction } from 'src/app/utils';
import { Car } from './models';
import { VehicleService } from './providers/vehicle-service/vehicle.service';

export class CarUtils {
    constructor(
        private translate: TranslateService,
        private alertController: AlertController,
        private vehicleService: VehicleService
    ) {}
    invalidVINAlert(emptyMessage?: boolean) {
        this.translate.get(['ENTER_VALID_VIN', 'CAR_NOT_FOUND', 'ACCEPT']).subscribe(async (res) => {
            const alert = await this.alertController.create({
                header: emptyMessage ? res.CAR_NOT_FOUND : res.ENTER_VALID_VIN,
                buttons: [
                    {
                        text: res.ACCEPT,
                    },
                ],
            });
            await alert.present();
        });
    }

    async carSelect(cars: Car[], callback) {
        this.translate.get(['SELECT_CAR', 'ACCEPT', 'CANCEL']).subscribe(async (res) => {
            const inputs = [];
            for (let i = 0; i < cars.length; i++) {
                const element = cars[i];
                inputs.push({
                    name: `car${i}`,
                    type: 'radio',
                    label: `${element.maker.value} ${element.model.value} ${element.year}`,
                    value: element,
                });
            }
            const alert = await this.alertController.create({
                header: res.SELECT_CAR,
                inputs,
                buttons: [
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                    {
                        text: res.ACCEPT,
                        handler: (car) => {
                            callback(car);
                        },
                    },
                ],
            });
            await alert.present();
        });
    }

    async continueVin(vinNumber: string, qrId: number, callback, forceAssociate?: boolean) {
        if (vinNumber) {
            if (vinNumber.length >= 4 || vinNumber.length === 17) {
                this.vehicleService
                    .findCarByVin(vinNumber, qrId, forceAssociate)
                    .then(async (cars) => {
                        if (cars.length) {
                            callback(cars);
                        } else {
                            this.invalidVINAlert(true);
                        }
                    })
                    .catch((error) => {
                        if (error && error.error.code === '-11') {
                            confirmAction(
                                {
                                    message: error.error.message,
                                    callback: () => {
                                        this.continueVin(vinNumber, qrId, callback, true);
                                    },
                                    yesMode: true,
                                },
                                this.translate,
                                this.alertController
                            );
                        }
                    });
            } else {
                this.invalidVINAlert();
            }
        } else {
            this.invalidVINAlert();
        }
    }
}
