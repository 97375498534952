import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

interface SelectorObj {
    id: number;
    value: string;
    isChecked?: boolean;
}

@Component({
    selector: 'app-filter-selector',
    templateUrl: './filter-selector.component.html',
    styleUrls: ['./filter-selector.component.scss'],
})
export class FilterSelectorComponent {
    sourceList: any[];
    list: SelectorObj[];
    filtered: SelectorObj[];
    initSelected: number;
    selected: SelectorObj;
    multipleSelected: SelectorObj[];
    title: string;
    filterField: string;
    multiple = false;

    constructor(private modalCtrl: ModalController) {}

    ionViewDidEnter() {
        this.list = this.sourceList.map((s: any) => ({ id: s.id, value: s[this.filterField] }));
        console.log(this.multipleSelected);
        if (this.multipleSelected && this.multipleSelected.length) {
            this.multipleSelected.forEach((s) => {
                for (const i in this.list) {
                    if (+s.id === +this.list[i].id) {
                        this.list[i].isChecked = true;
                        break;
                    }
                }
            });
        }
        this.resetList();
    }

    resetList() {
        this.filtered = this.list;
    }

    filterList(event) {
        const query = event.detail.value;
        if (query === '') {
            this.resetList();
        } else {
            this.filtered = this.list.filter((e) => e.value.toLowerCase().includes(query.toLowerCase()));
        }
    }

    radioGroupChange(event) {
        this.selected = this.filtered.find((e) => e.id === event.detail.value);
    }

    checkboxChange(event, id) {
        for (const obj in this.list) {
            if (this.list[obj].id === id) {
                this.list[obj].isChecked = event.detail.checked;
                break;
            }
        }
    }

    selectObj() {
        if (!this.multiple) {
            if (this.selected) {
                const found = this.sourceList.find((s: any) => s.id === this.selected.id);
                const index = this.sourceList.indexOf(found);
                this.dismiss({ found, index });
            }
        } else {
            this.dismiss({ found: this.list.filter((o) => o.isChecked) });
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
