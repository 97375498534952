import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { APP_CONFIG, AppConfig } from './app.config';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpService } from './interceptors/http.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthService } from './providers/auth-service/auth.service';
import { SettingsComponent } from './components/settings/settings.component';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { ImageViewerComponent } from './pages/image-viewer/image-viewer.component';
import { FilterSelectorComponent } from './modals/filter-selector/filter-selector.component';
import { FormsModule } from '@angular/forms';
import { NotificationService } from './providers/notification-service/notification.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { ViewLocationComponent } from './modals/view-location/view-location.component';
import { StorageService } from './services/storage.service';
import { BarcodeService } from './services/barcode.service';
import { BuscarVehiculoComponent } from './modals/buscar-vehiculo/buscar-vehiculo.modal';
import { ModalHelper } from './modals/modal.helper';
import { QrDetailComponent } from './modals/qr-detail/qr-detail.component';
import { CarListComponent } from './modals/car-list/car-list.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { EventDetailComponent } from './modals/event-detail/event-detail.component';
import { PipesModule } from './pipes/pipes.module';
import { CarListPopoverComponent } from './components/car-list-popover/car-list-popover.component';
import { EmailComponent } from './modals/email/email.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SettingsComponent,
        FilterSelectorComponent,
        ImageViewerComponent,
        ViewLocationComponent,
        BuscarVehiculoComponent,
        QrDetailComponent,
        CarListComponent,
        EventDetailComponent,
        CarListPopoverComponent,
        EmailComponent,
    ],
    entryComponents: [
        SettingsComponent,
        FilterSelectorComponent,
        ImageViewerComponent,
        ViewLocationComponent,
        BuscarVehiculoComponent,
        QrDetailComponent,
        CarListComponent,
        EventDetailComponent,
        CarListPopoverComponent,
        EmailComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        PipesModule,
        IonicStorageModule.forRoot({ name: 'SRS_KTA_DB' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        FormsModule,
        GoogleMapsModule,
    ],
    providers: [
        ScreenOrientation,
        BarcodeScanner,
        AuthService,
        TranslateService,
        NotificationService,
        StorageService,
        BarcodeService,
        ModalHelper,
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpService,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
