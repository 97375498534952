import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(value: string) {
        if (!value.length) {
            value = '';
        } else if (value.length === 10) {
            value = value.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
        }
        return value;
    }
    static applyTransform(value: string) {
        if (!value.length) {
            value = '';
        } else if (value.length === 10) {
            value = value.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
        }
        return value;
    }
}
