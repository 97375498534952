import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
    apiEndpoint: string;
    nodeEndpoint: string;
    isProd: boolean;
    appVersion: string;
    appName: string;
    pushAppId: string;
    pushGoogleId: string;
    playStore: string;
    appStore: string;
}

export const AppConfig: IAppConfig = {
    isProd: environment.production,
    apiEndpoint: environment.apiEndpoint,
    nodeEndpoint: environment.nodeEndpoint,
    appVersion: '1.0.10',
    appName: 'SRS-KTA',
    pushAppId: '3a4a8e76-12d4-4e1c-87d2-ccf0ae4b90f9',
    pushGoogleId: '833265030374',
    playStore: 'market://details?id=com.srs.keytracker',
    appStore: 'https://apps.apple.com/us/app/srs-key-tracking-assistance/id1562594366',
};
