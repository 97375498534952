import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { GlobalEvents } from '../services/events.service';

export class BaseService {
    events: GlobalEvents;
    http: HttpClient;
    alertCtrl: AlertController;
    constructor(http: HttpClient, private globalEvents: GlobalEvents, private alertController: AlertController) {
        this.events = globalEvents;
        this.alertCtrl = alertController;
        this.http = http;
    }

    async apiGet(url: string, options?: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.http.get(url, options).subscribe(
                (resp: any) => {
                    resolve(resp);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async apiPostProgress(url: string, body: any, options?: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.http.post(url, body, options).subscribe(
                (event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round((100 * event.loaded) / event.total);
                        this.events.publish({ event: options.event, data: percentDone });
                    } else if (event instanceof HttpResponse) {
                        resolve(event);
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async apiPost(url: string, body: any, options?: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.http.post(url, body, options).subscribe(
                (resp: any) => {
                    resolve(resp);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async apiPut(url: string, body: any, options?: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.http.put(url, body, options).subscribe(
                (resp: any) => {
                    resolve(resp);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async apiDelete(url: string, options?: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.http.delete(url, options).subscribe(
                (resp: any) => {
                    resolve(resp);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    async presentAlert(data: { header?: string; message?: string }) {
        const { header, message } = data;
        const alert = await this.alertCtrl.create({
            header,
            message,
            buttons: ['OK'],
        });

        await alert.present();
    }
}
