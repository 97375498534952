import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-car-list-popover',
    templateUrl: './car-list-popover.component.html',
    styleUrls: ['./car-list-popover.component.scss'],
})
export class CarListPopoverComponent implements OnInit {
    showFilterOption = true;
    showDeleteOption = false;
    constructor(private popoverController: PopoverController) {}

    ngOnInit() {}

    sendEmail() {
        this.dismissPopover({ event: 'ENABLE_SEND_EMAIL' });
    }

    print() {
        this.dismissPopover({ event: 'ENABLE_PRINT' });
    }

    dismissPopover(data?: any) {
        this.popoverController.dismiss(data);
    }
}
