import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { Invoice, invoiceToRequest, getInvoiceFromResponse, toStringDate, getEmailFromResponse } from 'src/app/models';
import { AuthService } from '../auth-service/auth.service';
import { GlobalEvents } from 'src/app/services/events.service';
import { BaseService } from '../BaseService';
import { AlertController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends BaseService {
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private authService: AuthService,
        http: HttpClient,
        events: GlobalEvents,
        alertCtrl: AlertController
    ) {
        super(http, events, alertCtrl);
    }

    async getInvoice(id: number) {
        const user = await this.authService.getUser();
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/invoice.php?id_usuario=${user.id}&id=` + id);
        return getInvoiceFromResponse(resp.data as any);
    }

    async getInvoiceByFilter(idWorkflowIn: string, providerId: string, filters?: any) {
        const ids = await this.authService.getActiveIds();
        const user = await this.authService.getUser();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            idDealerProvider: providerId ? providerId : null,
            idDealer: ids.idDealer,
            idUserLogged: !user.isDetailer ? ids.idUsuario : null,
            idWorkflowIn,
            showScanner: 1,
            orderBy: 'i.id_workflow, i.fecha_alta DESC',
            allowUserApprove: ids.idUsuario,
            ...filters,
        });
        return resp.data.map((i) => getInvoiceFromResponse(i as any));
    }

    async validateInvoice(invoice: Invoice, idCar: number) {
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            idInvServiceExistsIn: invoice.invoiceServices.map((s) => s.id).join(','),
            fechaAltaDesde: toStringDate(invoice.entryDate, true),
            fechaAltaHasta: toStringDate(invoice.entryDate, true),
            idCar,
        });
        return resp.data.length;
    }

    async sendInvoice(invoice: Invoice, userId: number, carId: number, dealerId: number) {
        const activeIds = await this.authService.getActiveIds();
        const invoiceRequest = invoiceToRequest(invoice, userId, carId, dealerId);
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            ...invoiceRequest,
            idUsuario: activeIds.idUsuario,
        });
        return getInvoiceFromResponse(resp.data as any);
    }

    async sendInvoiceEmail(idsInvoice: string, emailto: string) {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/sendmail.php`, {
            tipo: 'sendmail',
            idsInvoice,
            emailto,
            idDealer,
            idAuthor: idUsuario,
        });
    }

    async getSentEmails(fromDate: string, toDate: string) {
        const { idUsuario } = await this.authService.getActiveIds();
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.emails.php`, {
            fechaDesde: fromDate,
            fechaHasta: toDate,
            idAuthor: idUsuario,
        });
        return data.map((d) => getEmailFromResponse(d));
    }

    async getRecentEmails(): Promise<string[]> {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/getEmails.php?id_author=${idUsuario}&id_dealer=${idDealer}`
        );
        return data.map((e) => e.email);
    }
    async deleteRecentEmail(email: string): Promise<void> {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        return await this.apiDelete(
            `${this.config.apiEndpoint}/php/api/getEmails.php?id_author=${idUsuario}&email=${email}&hide_loading`
        );
    }

    async printWO(idsInvoice: string) {
        const url = `${this.config.apiEndpoint}/modulos/invoices/php/invoice_pdf.php?ids_invoices=${idsInvoice}`;
        await Browser.open({ url });
    }

    async approveInvoice(approve: boolean, idInvoice: number) {
        const activeIds = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/api.invoiceActions.php`, {
            idInvoice,
            approve: approve ? '1' : '0',
            tipo: 'approved',
            idUsuario: activeIds.idUsuario,
        });
    }

    async getImages(invoiceId: number, carId: number) {
        const imgList: string[] = await this.apiGet(
            `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}?env=${this.config.isProd ? 'prod' : 'test'}`
        );
        const resp: { name: string; url: string }[] = [];
        imgList.forEach((i) => {
            resp.push({
                url: `${this.config.nodeEndpoint}/invoices${
                    this.config.isProd ? '' : '_test'
                }/img/${carId}/${invoiceId}/${i}`,
                name: i,
            });
        });
        return resp;
    }

    async getWoImages(invoiceId: number, carId: number) {
        const imgList: string[] = await this.apiGet(
            `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}?env=${this.config.isProd ? 'prod' : 'test'}`
        );
        const resp: { name: string; url: string }[] = [];
        imgList.forEach((i) => {
            resp.push({
                url: `${this.config.nodeEndpoint}/wo${
                    this.config.isProd ? '' : '_test'
                }/img/${carId}/${invoiceId}/${i}`,
                name: i,
            });
        });
        return resp;
    }

    async deleteImage(invoiceId: number, carId: number, filename: string) {
        return this.apiDelete(
            `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}/${filename}?env=${
                this.config.isProd ? 'prod' : 'test'
            }`
        );
    }

    async deleteWoImage(invoiceId: number, carId: number, filename: string) {
        return this.apiDelete(
            `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}/${filename}?env=${
                this.config.isProd ? 'prod' : 'test'
            }`
        );
    }

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    submitImage(invoiceId: number, carId: number, image: string) {
        const blob = this.dataURItoBlob(image);
        const fd = new FormData(document.forms[0]);
        fd.append('file', blob);
        return new Promise<{ name: string; url: string }>(async (resolve, reject) => {
            this.http
                .post(
                    `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}/img_upload?env=${
                        this.config.isProd ? 'prod' : 'test'
                    }`,
                    fd,
                    {
                        reportProgress: true,
                        observe: 'events',
                    }
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve({
                                url: `${this.config.nodeEndpoint}/invoices${
                                    this.config.isProd ? '' : '_test'
                                }/img/${carId}/${invoiceId}/${event.body.fileUrl}`,
                                name: event.body.fileUrl,
                            });
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    submitWoImage(invoiceId: number, carId: number, image: string) {
        const blob = this.dataURItoBlob(image);
        const fd = new FormData(document.forms[0]);
        fd.append('file', blob);
        return new Promise<{ name: string; url: string }>(async (resolve, reject) => {
            this.http
                .post(
                    `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}/img_upload?env=${
                        this.config.isProd ? 'prod' : 'test'
                    }`,
                    fd,
                    {
                        reportProgress: true,
                        observe: 'events',
                    }
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'wo_image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve({
                                url: `${this.config.nodeEndpoint}/wo${
                                    this.config.isProd ? '' : '_test'
                                }/img/${carId}/${invoiceId}/${event.body.fileUrl}`,
                                name: event.body.fileUrl,
                            });
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async updateFields(data: {
        woId: number;
        workflow: { id: number; comment?: string };
        employees?: number[];
        updateFields: string;
    }) {
        const { woId, workflow, employees, updateFields } = data;
        const activeIds = await this.authService.getActiveIds();
        return this.apiPut(`${this.config.apiEndpoint}/php/api/invoiceUpdateFields.php`, {
            author: { id: activeIds.idUsuario },
            id: woId,
            workflow,
            employees,
            updateFields,
        });
    }

    getDownloadUrl(file, emailId) {
        return `${this.config.apiEndpoint}/php/download.php?id_queue=${emailId}&id_file=${file.id}&file=${file.name}`;
    }
}
